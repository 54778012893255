<template>
  <div class="container py-4">
    <h1>Публичная оферта</h1>
    <h2>Лицензионное соглашение на предоставление доступа к программе для ЭВМ "Market Metrics"</h2>
    <p> Индивидуальный предприниматель Калин Иван Викторович, именуемый далее «Лицензиар»,
      действующий на основании ОГРНИП 316527500088238, с одной стороны, настоящей публичной офертой,
      размещенной на <a href="https://marketmetrics.online">https://marketmetrics.online</a>, предлагает любому
      заинтересованному физическому лицу или юридическому лицу, именуемому далее - «Лицензиат», с другой стороны,
      вместе далее именуемые «Стороны», а по отдельности - «Сторона», заключить настоящее лицензионное соглашение
      (далее - «Договор») на неисключительное право доступа к программе (ПО) для ЭВМ "Market Metrics" на следующих
      условиях:
    </p>
    <p>
      Настоящая публичная оферта о заключении лицензионного соглашения на предоставление доступа к ПО для ЭВМ
      "Market Metrics" предлагается в соответствии со ст. 435 и 437 ГК РФ, и Стороны признают её юридическую силу.
      Договор считается заключенным в соответствии со ст. 438 ГК РФ с момента его Акцепта Лицензиатом, а именно:
    </p>
    <ul>
      <li>Оплаты лицензионного вознаграждения Лицензиару в полном объеме и порядке, установленными Договором.</li>
      <li>При этом датой акцепта (датой заключения Договора) считается фактическая дата получения оплаты Исполнителем на
        расчетный счет.
      </li>
    </ul>
    <p>
      Оплачивая лицензионное вознаграждение, Лицензиат:
    </p>
    <ul>
      <li>гарантирует достоверность и актуальность сведений, необходимых для получения доступа к Программе;</li>
      <li>гарантирует, что является совершеннолетним, и полностью дееспособным лицом;</li>
      <li>полностью соглашается с условиями Договора без каких-либо изъятий и ограничений.</li>
    </ul>
    <p>
      Перед началом любого использования указанной ниже Программы для ЭВМ внимательно ознакомьтесь с условиями её
      использования,
      содержащимися в настоящем Договоре. Если Вы не согласны безоговорочно принять условия настоящего Договора, Вы не
      имеете права
      использовать Программу.
    </p>

    <h3>1. Описание программы для ЭВМ "Market Metrics"</h3>
    <p>
      1.1. Программа (ПО) для ЭВМ «Market Metrics» позволяет производить анализ продаж и заказов, а также передаваемых
      данных
      аналитики на маркетплейсе Wildberries. ПО предназначено для:
    </p>
    <ul>
      <li>1.1.1. Получения данных и аналитики по официальному API Wildberries - <a href="https://openapi.wb.ru">https://openapi.wb.ru</a>
      </li>
      <li>1.1.2. Получения данных и аналитики wildberries.ru</li>
    </ul>
    <p>
      Правообладателем ПО для ЭВМ «Market Metrics» является ИП Калин Иван Викторович.
    </p>
    <p>
      1.2. Маркетплейс Wildberries не является частью ПО для ЭВМ «Market Metrics», права доступа к данным сервисам
      Лицензиат
      получает самостоятельно.
    </p>

    <h3>2. Термины, используемые в договоре и приложениях к нему</h3>
    <p>2.1. Лицензиар (Правообладатель) – ИП Калин Иван Викторович.</p>
    <p>2.2. Лицензиат – физическое или юридическое лицо, принявшее условия настоящего Договора.</p>
    <p>
      2.3. Программа – Программа (ПО) для ЭВМ “Market Metrics” (как в целом, так и ее компоненты), являющаяся
      представленной в объективной форме совокупностью модулей, аппаратных средств, данных и команд Правообладателя,
      в том числе исходного текста, базы данных, аудиовизуальных произведений, включённых Лицензиаром в состав указанной
      Программы для ЭВМ, а также любая документация по её использованию, доступ к которым предоставляется Лицензиату
      с момента регистрации и использования Сайта Правообладателя <a href="https://marketmetrics.online">https://marketmetrics.online</a>,
      а также на всех внутренних страницах указанного сайта. Программа расположена на Сайте Лицензиара <a
        href="https://marketmetrics.online">https://marketmetrics.online</a>,
      доступ к Программе производится через браузер ЭВМ.
    </p>
    <p>2.4. Сайт (Сервис) – <a href="https://marketmetrics.online">https://marketmetrics.online</a></p>
    <p>
      2.5. Модуль – обособленное программное средство, являющееся частью Программы, выполняющее на Сервисе заданные
      функции, выраженное в уникальной совокупности данных и команд (могут быть представлены на любом языке и в любой
      форме,
      включая исходный текст и объектный код), предназначенное для выполнения функций Сервиса. Модули подлежат
      использованию по
      сети интернет исключительно с Сайта <a href="https://marketmetrics.online">https://marketmetrics.online</a>.
    </p>
    <p>
      2.6. Сопровождение - дополнительная услуга ежемесячного сопровождения, предоставляемая Лицензиаром - ведение
      рекламных кампаний для Wildberries внутри Программы (Приложение №1 к настоящей оферте).
    </p>
    <p>
      2.7. Материалы Сайта (Сервиса) – все текстовые, графические материалы, размещённые на Сервисе и являющиеся
      объектами
      интеллектуальной собственности Лицензиара, а также оформление и расположение указанных материалов (дизайн).
    </p>
    <p>
      2.8. Использование Программы – использование функциональных возможностей и/или запуск Программы в порядке,
      определенном
      пользовательской документацией и настоящим Договором.
    </p>
    <p>
      2.9. Авторизованный пользователь – пользователь, зарегистрированный в Программе и авторизовавшийся в ней как
      минимум один раз.
    </p>
    <p>
      2.10. Личный кабинет (Аккаунт) – информационный ресурс, персональный раздел сайта <a
        href="https://marketmetrics.online">https://marketmetrics.online</a>,
      в котором Лицензиат может самостоятельно и в любое время получать доступ к Программе и выбранным Модулям в течение
      срока действия выбранного Тарифа.
    </p>
    <p>2.11. Регистрация – действие Лицензиата на Сайте, направленное на создание Аккаунта.</p>
    <p>
      2.12. Учетная запись – запись в системе Лицензиара, хранящая аутентификационные данные – логины и соответствующие
      им пароли
      (как временные, так и постоянные), позволяющие идентифицировать и авторизовать Лицензиата.
    </p>
    <p>
      2.13. Персональная информация Лицензиата: персональная информация, которую Лицензиат предоставляет о себе
      самостоятельно
      при активации или в процессе использования Программы Market Metrics, включая персональные данные Лицензиата;
      данные,
      которые автоматически передаются в процессе использования Программы Market Metrics, в том числе, но не
      исключительно:
      IP, MAC, ICCID адреса, данные или иные уникальные данные об оборудовании Лицензиата, номера телефонов,
      региональные коды,
      информация о программном обеспечении Лицензиата, с помощью которых осуществляется доступ к Программе Market
      Metrics;
      иная информация о Лицензиате, сбор и/или предоставление которой определяется и оговаривается с Лицензиатом
      индивидуально.
    </p>
    <p>
      2.14. Лицензионное вознаграждение – сумма, оплачиваемая Лицензиатом по настоящему Договору в соответствии с
      выбранным
      Тарифным планом предоставления права использования Программы.
    </p>
    <p>
      2.15. Подписка – ежемесячный доступ к Программе и/или услугам Сопровождения на период, указанный в выбранном
      Лицензиатом
      Тарифном плане. Подписка предоставляется на основании внесения Лицензиатом абонентских платежей в указанном на
      Сайте размере.
    </p>
    <p>
      2.16. Тарифный план – фиксированная стоимость прав доступа к Программе, подлежащая оплате Лицензиатом в
      зависимости от
      выбранных им Модулей, функций и срока доступа к Программе. Тарифный план опубликован на Сайте по ссылке
      <a href="https://marketmetrics.online">https://marketmetrics.online</a> и к настоящему Договору.
    </p>
    <p>
      2.17. Период действия тарифа (Срок действия) — период действия прав доступа к Программе, устанавливаемый Тарифным
      планом.
    </p>
    <p>
      2.18. Маркетплейс – платформа электронной коммерции, интернет-магазин электронной торговли, предоставляющий
      информацию о
      продукте или услуге третьих лиц.
    </p>
    <h3>3. ПРЕДМЕТ ДОГОВОРА</h3>
    <p>
      3.1. Лицензиар предоставляет Лицензиату право использования (простую неисключительную лицензию) Программы для ЭВМ
      "Market Metrics" (далее по тексту - Лицензия / доступ к Программе) в рамках её функциональных возможностей путем
      подключения к Программе через сеть Интернет, исключительно для самостоятельного использования Лицензиатом без
      права
      сублицензирования третьим лицам.
    </p>
    <p>3.2. Лицензия предоставляется на основании приобретенной Подписки, оформленной через Сайт.</p>
    <p>
      3.3. Лицензиат имеет право осуществлять продление (пролонгацию) Лицензии на действующем Аккаунте, докупку
      дополнительных
      Модулей, смену Тарифного плана, подключения Сопровождения.
    </p>
    <p>
      3.4. Лицензиар предоставляет Лицензиату право использования Программы без ограничения на территории всего мира в
      порядке
      и на условиях, предусмотренных действующим законодательством Российской Федерации, настоящим Договором.
    </p>
    <p>
      3.5. Необходимым условием для представления Лицензии и дополнительных услуг является:
    </p>
    <p>3.5.1. Регистрация Лицензиата на Сайте размещения ПО "Market Metrics";</p>
    <p>3.6.2. Подключение к Личному кабинету "Market Metrics", путем заполнения формы на Сайте <a
        href="https://marketmetrics.online">https://marketmetrics.online</a></p>
    <p>3.6.2.1. В контексте настоящего пункта Лицензиару, а также сотрудникам Лицензиара запрещается осуществлять
      следующие действия: распространение сведений / предоставление доступа / подключение других пользователей и
      осуществление иных действий, влекущих доступ в Аккаунт маркетплейса Лицензиата неуполномоченных лиц.</p>
    <p>3.7. Лицензиар гарантирует, что с его Стороны, а также со стороны его ответственных сотрудников не будут
      осуществлены действия в Аккаунте маркетплейса Лицензиата, указанные в п. 3.5.2.1.</p>
    <p>
      3.8. В случае нарушения вышеуказанных обязательств Лицензиар в силу действующего законодательства РФ несёт
      ответственность
      в размере убытков, причинённых Лицензиату.
    </p>
    <p>3.9. Лицензиат имеет право в любой момент отказаться от продления Лицензии.</p>

    <h3>4. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ ПРАВА ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ</h3>
    <p>
      4.1. Лицензиат имеет право использования Программы в рамках условий настоящего Договора на территории любой страны
      мира.
    </p>
    <p>
      4.2. Предоставление права использования Программы производится с момента акцепта - оплаты Лицензиатом
      лицензионного
      вознаграждения в размере стоимости Подписки на выбранные Лицензиатом самостоятельно Модули и в соответствии с
      Тарифным
      планом, указанным на Сайте. Предоставление дополнительных услуг Сопровождения производится по заявке Лицензиата
      исключительно
      после покупки Лицензии и в рамках доступного функционала Программы. Стоимость дополнительных услуг оплачивается
      Лицензиатом
      отдельно и не входит в стоимость лицензионного вознаграждения.
    </p>
    <p>
      4.3. Право использования демо-версии (доступ 7 календарных дней) Модулей Программы предоставляется Лицензиату с
      момента
      регистрации Лицензиата на Сайте без оплаты лицензионного вознаграждения за этот период.
    </p>
    <p>4.4. Регистрация осуществляется Лицензиатом самостоятельно на Сайте или с помощью Лицензиара.</p>
    <p>
      4.5. Лицензиат имеет право в рамках настоящего Договора размещать в Аккаунте принадлежащие ему данные, если это не
      нарушает
      условия настоящего Договора и Законодательство РФ.
    </p>
    <p>4.6. Лицензиату не предоставляется возможность и право модификации Программы.</p>
    <p>
      4.7. Лицензиат самостоятельно обеспечивает наличие оборудования, соответствующего техническим требованиям для
      использования
      Программы и доступа к сети Интернет.
    </p>
    <p>
      4.8. Лицензиат гарантирует, что он имеет все необходимые права на все данные, компьютерные программы или сервисы,
      которые
      используются им в связи и совместно с использованием Программы, и что такие действия не нарушают прав третьих лиц.
    </p>
    <p>
      4.9. Оплата лицензионного вознаграждения и ежемесячного Сопровождения производится в порядке Абонентского платежа
      либо
      периодических Абонентских платежей и зависит от выбранного Лицензиатом Тарифного плана. Во всех случаях Лицензиат
      перечисляет
      Абонентский платеж в качестве 100% предоплаты за период, указанный в Тарифном плане.
    </p>
    <p>
      4.10. Дата начала доступа к Программе ЭВМ "Market Metrics" и дата начала Сопровождения исчисляются, начиная со дня
      внесения первого Абонентского платежа.
    </p>
    <p>
      4.11. В случае, если выбранным Лицензиатом Тарифным планом предусмотрено периодическое внесение Абонентских
      платежей
      (например, ежемесячно, ежеквартально, либо иная периодичность), при внесении Лицензиатом предоплаты в виде
      Абонентского
      платежа за каждый следующий период (например, месяц, квартал), Договор считается продленным на оплачиваемый
      Лицензиатом срок.
    </p>
    <p>
      4.12. В отсутствие оплаты на новый период Лицензиат не вправе требовать предоставления доступа к Программе и/или
      услугам
      Сопровождения. В случае последующего внесения Лицензиатом предоплаты по Тарифному плану Лицензиат вправе требовать
      предоставления доступа к Программе и/или услугам Сопровождения за предоплаченный период, начиная с даты внесения
      оплаты.
    </p>
    <p>
      4.13. В случае отказа Лицензиата от Договора до окончания оплаченного срока действия Лицензии и/или услуг
      Сопровождения
      Стороны исходят из правил, установленных для абонентского договора (ст. 429.4 ГК РФ).
    </p>
    <p>
      4.14. Акцептуя настоящую оферту, Лицензиат принимает условия о возврате оплаты лицензионного вознаграждения и
      услуг
      Сопровождения с учетом абонентского характера такого Договора, и признаёт, что не вправе требовать возврата
      Абонентского
      платежа по текущему периоду.
    </p>
    <h2>6. Порядок расчетов</h2>
    <p>
      6.1. Общая стоимость предоставления права использования Программы:
      лицензионное вознаграждение и/или дополнительных услуг Сопровождения (по
      заявке Лицензиата), подлежащая оплате Лицензиатом, указывается в
      выставленных Счетах платежных систем при оформлении Подписки на сайте.
    </p>
    <p>
      6.2. Для выставления отдельного Счета для оплаты на расчетной счет
      Лицензиара по настоящему Договору Лицензиат обязуется предоставить
      Лицензиару карточку компании (реквизиты) с указанием идентификатора ЭДО
      компании для отправки бухгалтерских документов.
    </p>
    <p>
      6.3. Лицензиат оплачивает лицензионное вознаграждение за предоставление
      права использования Программы согласно выставленного Счета в размере
      100% (сто процентов) от суммы, указанной в Счете.
    </p>
    <p>
      6.4. Все платежи по настоящему Договору осуществляются в безналичной
      форме в рублях Российской Федерации путём перечисления денежных средств
      на расчётный счёт Лицензиара согласно выставленного Счета либо путём
      внесения оплаты через платежную систему, НДС не облагается в
      соответствии с гл. 26.2 НК РФ в связи с применением Лицензиаром УСН.
    </p>
    <p>
      6.5. Оплата лицензионного вознаграждения за предоставление права
      использования Программы является акцептом настоящей оферты.
    </p>
    <p>
      6.6. Оплата лицензионного вознаграждения подтверждается выдачей
      электронного чека. Чек отправляется на электронную почту Лицензиата,
      указанную при оплате.
    </p>
    <p>
      6.7. По требованию Лицензиата Лицензиар предоставляет универсальный
      передаточный документ (далее – УПД) через электронный документооборот
      (далее - ЭДО). Обмен между Лицензиаром и Лицензиатом происходит только
      первичными документами. УПД выставляются в течение 7 (семи) дней с
      момента зачисления денежных средств на расчетный счет Лицензиара в
      размере суммы выбранного Лицензиатом Тарифного плана.
    </p>
    <p>
      6.8. Для получения первичных документов - УПД, оформленных в
      соответствии с требованиями, предъявляемыми действующим
      законодательством РФ к первичным учетным документам, Лицензиат запрашивает
      отправку документов через ЭДО на сайте
      <a href="https://marketmetrics.online">marketmetrics.online</a> на
      странице Оплата - Счета.
    </p>
    <p>
      6.9. С момента зачисления денежных средств на расчетный счет Лицензиара
      Лицензиат получает доступ к материалам, Модулям Сервиса, доступ к
      которым предоставляется в рамках Тарифных планов, услуги доступа к
      Программе признаются Сторонами, оказанными надлежащим образом и
      принятыми Лицензиатом.
    </p>
    <h2>7. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
    <h3>7.2. Лицензиар вправе:</h3>
    <ul>
      <li>7.2.1. Получать от Лицензиата разъяснения по всем вопросам, возникающим в ходе предоставления Лицензии, и
        любую дополнительную информацию, необходимую для выполнения своих обязательств по настоящему договору.
      </li>
      <li>7.2.2. Самостоятельно определять порядок и способы предоставления Лицензии по настоящему Договору.</li>
      <li>7.2.3. Лицензиар имеет право заблокировать доступ Лицензиата к Программе в следующих случаях:
        <ul>
          <li>получения больше 5000 запросов к Сервису в течение дня;</li>
          <li>при использовании Сервиса более чем с 3 IP адресов одновременно;</li>
          <li>при использовании Сервиса напрямую через программный интерфейс.</li>
        </ul>
      </li>
    </ul>

    <h3>7.3. Лицензиат обязуется:</h3>
    <ul>
      <li>7.3.1. Предоставить Лицензиару сведения, материалы и документы, необходимые для предоставления Доступа.</li>
      <li>7.3.2. Своевременно и в полном объеме вносить необходимые платежи за право пользования Программой согласно
        разделу 6 настоящего Договора.
      </li>
      <li>7.3.3. Выполнять рекомендации по использованию Программы, полученные от технических специалистов Лицензиара.
      </li>
    </ul>

    <h3>7.4. Лицензиат вправе:</h3>
    <ul>
      <li>7.4.1. Получать от Лицензиара устные и письменные объяснения о ходе исполнения обязательств по настоящему
        Договору, при этом не вмешиваясь в деятельность Лицензиара.
      </li>
      <li>7.4.2. Требовать от Лицензиара надлежащего исполнения обязательств, принятых последним на себя согласно
        условиям настоящего Договора.
      </li>
    </ul>

    <h3>7.5. Лицензиат не вправе:</h3>
    <ul>
      <li>Предоставлять третьим лицам право использования Программы способами, переданными ему в соответствии с
        настоящим Договором (заключать сублицензионные договоры).
      </li>
    </ul>

    <h2>8. ОТВЕТСТВЕННОСТЬ</h2>
    <ul>
      <li>8.1. Программа предоставляется по принципу «как есть», и Лицензиар не гарантирует, что все ее функциональные
        возможности будут отвечать ожиданиям Лицензиата и смогут быть применимы для конкретной его цели.
      </li>
      <li>8.2. Лицензиар не инициирует и не контролирует размещение Лицензиатом любой информации в процессе
        использования Программы, не влияет на ее содержание и целостность, а также в момент размещения указанной
        информации не знает и не может знать - нарушает ли она охраняемые законом права и интересы третьих лиц,
        международные договоры и действующее законодательство Российской Федерации.
      </li>
      <li>8.3. Лицензиат несет ответственность в полном объеме за:
        <ul>
          <li>а) соблюдение всех требований законодательства, в том числе законодательства о рекламе, об
            интеллектуальной собственности, о конкуренции, о персональных данных;
          </li>
          <li>б) достоверность сведений, передаваемых Лицензиару, достоверность сведений, указанных в материалах о
            товарах;
          </li>
          <li>в) соответствие товаров, всем требованиям законодательства Российской Федерации, за то, что товары не
            являются фальсифицированными и/или контрафактными.
          </li>
        </ul>
      </li>
      <li>8.4. Лицензиар не несет ответственности перед Лицензиатом за любой ущерб, любую потерю доходов, прибыли,
        информации или сбережений, связанных с использованием или с невозможностью использования Программы.
        Использование Программы в своей деятельности является предпринимательским риском Лицензиата.
      </li>
      <li>8.5. Если при использовании Программы будут обнаружены ошибки, Лицензиар предпримет все возможные с его
        Стороны меры для их исправления. Стороны соглашаются, что точное определение срока устранения ошибки не может
        быть установлено, так как Программа тесно взаимодействует с другими программами для ЭВМ сторонних разработчиков,
        операционными системами и аппаратными ресурсами Лицензиата, т.е. работоспособность и время устранения проблем в
        полной мере не зависят только от Лицензиара.
      </li>
      <li>8.6. За нарушение условий настоящего Договора любой из Сторон наступает ответственность, предусмотренная
        законодательством Российской Федерации.
      </li>
    </ul>

    <h2>9. КОНФИДЕНЦИАЛЬНОСТЬ, ОБРАБОТКА И ИСПОЛЬЗОВАНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
    <ul>
      <li>9.1. Стороны гарантируют полное соблюдение всех условий обработки, хранения и использования полученных
        персональных данных согласно ФЗ «О персональных данных» № 152 ФЗ от 27.07.2006г.
      </li>
      <li>9.2. Лицензиар обрабатывает персональные данные Лицензиата, пользователей, иных лиц, сведения о которых
        предоставил Лицензиат в порядке, предусмотренном Политики в отношении обработки персональных данных, размещен
        Лицензиара.
      </li>
      <li>9.3. Лицензиар собирает и хранит только те персональные данные, которые необходимы для целей исполнения
        настоящего Договора.
      </li>
      <li>9.4. Лицензиат, регистрируясь на Сайте, выражает свое согласие на использование Лицензиаром сведений о его
        персональных данных в соответствии с законодательством Российской Федерации и для целей организации исполнения
        Договора.
      </li>
    </ul>
    <!-- Оферта лицензионного соглашения "Market Metrics" -->

    <!-- 10. ГАРАНТИИ СТОРОН -->
    <section>
      <h2>10. ГАРАНТИИ СТОРОН</h2>
      <p>10.1. Акцептирую настоящую оферту Лицензиат подтверждает и гарантирует Лицензиару, что:</p>
      <ul>
        <li>10.1.1. Лицензиат указал достоверные данные, в том числе данные юридического лица, персональные данные при
          регистрации в качестве пользователя на маркетплейсе и достоверные данные, в том числе данные юридического
          лица, персональные, при оформлении платежных документов по оплате услуг по настоящему Договору.
        </li>
        <li>10.1.2. Лицензиат полностью ознакомился с условиями Договора.</li>
        <li>10.1.3. Лицензиат полностью понимает значение и последствия своих действий в отношении заключения и
          исполнения Договора.
        </li>
        <li>10.1.4. Лицензиат обладает всеми правами и полномочиями, необходимыми для заключения и исполнения
          Договора.
        </li>
        <li>10.1.5. Размещение (воспроизведение, показ), иное использование в Программе материалов
          (фото/аудио/видео/текстового контента о товаре Лицензиата) по Договору не нарушает и не влечет за собой
          нарушение каких-либо прав третьих лиц и действующего законодательства, в связи с чем ответственность за
          содержание материалов возлагается на Лицензиата.
        </li>
        <li>10.1.6. Товары, сведения о которых содержатся в представленных Лицензиатом материалах, загруженных в
          Программу, законным способом были введены в гражданский оборот на территории Российской Федерации, а также
          соответствуют всем требованиям законодательства Российской Федерации, в том числе законодательству о
          техническом регулировании, не являются фальсифицированными и/или контрафактными.
        </li>
      </ul>
      <p>10.2. Стороны заявляют, гарантируют и обязуются:</p>
      <ul>
        <li>10.2.1. Следовать всему применимому законодательству, включая законам и постановлениям государственных
          органов, имеющих отношение к юрисдикции деятельности каждой из Сторон или к юрисдикции, имеющей отношение к
          Договору.
        </li>
      </ul>
    </section>

    <!-- 11. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ -->
    <section>
      <h2>11. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
      <ul>
        <li>11.1. Настоящий Договор вступает в силу с момента его акцепта, действует до окончания срока действия
          оплаченной Лицензии. В случае продления Лицензии настоящий Договор пролонгируется на соответствующий срок.
        </li>
        <li>11.2. По всем вопросам, не урегулированным настоящим Договором Стороны руководствуются действующим
          законодательством Российской Федерации.
        </li>
        <li>11.3. Лицензиар имеет право изменять условия настоящего Договора в одностороннем порядке путем размещения
          измененного текста на Сайте <a href="https://marketmetrics.online">https://marketmetrics.online</a>.
          Актуальная версия настоящего Договора всегда находится на Сайте Лицензиара по вышеуказанному адресу.
        </li>
        <li>11.4. В случае изменения реквизитов компании, адресов или расчетных реквизитов Сторон, Сторона, чьи
          реквизиты изменились, обязана уведомить об этом другую Сторону в течение 5 (пяти) рабочих дней с момента
          вступления в силу таких изменений.
        </li>
        <li>11.5. Переписка Сторон в процессе исполнения настоящего Договора ведется посредством электронной почты.
          Указанным способом Стороны могут направлять друг другу бухгалтерские документы, а также иные документы,
          связанные с исполнением настоящего Договора, комментарии, иные сообщения.
        </li>
        <li>11.6. Каждая из Сторон обязуется принимать полученные от другой Стороны по электронной почте сообщения и
          документы и руководствоваться содержащейся в них информацией независимо от того, будут ли такие сообщения и
          документы подписаны электронной цифровой подписью или нет. Моментом получения письма считается следующий день
          после направления.
        </li>
        <li>11.7. В случае если компетентный суд признает какие-либо положения настоящего Договора недействительными,
          Договор продолжает действовать в остальной части.
        </li>
      </ul>
    </section>

    <!-- 12. РЕКВИЗИТЫ ЛИЦЕНЗИАРА -->
    <section>
      <h2>12. РЕКВИЗИТЫ ЛИЦЕНЗИАРА</h2>
      <p>ИП Калин Иван Викторович</p>
      <p>ИНН: 522204387010</p>
      <p>ОГРНИП: 316527500088238</p>
      <p>Юридический адрес: 111401, ОБЛ. МУРМАНСКАЯ Р-Н ЛОВОЗЕРСКИЙ ПГТ. РЕВДА УЛ. МЕТАЛЛУРГОВ Д. 4, КВ. 46</p>
      <p>Контактный email: marketmetrics@yandex.ru</p>
      <p>Расчетный счет № 40802810400002388213</p>
      <p>Наименование банка: ООО "Банк»</p>
      <p>Корреспондентский счёт № 30101810145250000974</p>
      <p>БИК 044525974</p>
    </section>

    <!-- ДОПОЛНИТЕЛЬНАЯ УСЛУГА СОПРОВОЖДЕНИЕ ПРОГРАММЫ ДЛЯ ЭВМ "Market Metrics" -->
    <section>
      <h2>ДОПОЛНИТЕЛЬНАЯ УСЛУГА СОПРОВОЖДЕНИЕ ПРОГРАММЫ ДЛЯ ЭВМ "Market Metrics"</h2>
      <h3>Приложение №1 к Публичной оферте</h3>
      <h4>Перечень и основные характеристики</h4>
      <ul>
        <li>Получение отчетов: реализации, заказы, продажи, остатки, продвижение, рейтинг и отзывы</li>
        <li>Структурирование отчетов по товарам</li>
      </ul>

      <h4>1. Условия оказания услуги:</h4>
      <ul>
        <li>1.1. Услуги оказываются по ежемесячной Подписке. Оплата услуг производится в порядке Абонентского платежа
          либо периодических Абонентских платежей в размере 100 % предоплаты за выбранный Лицензиатом период.
        </li>
        <li>1.2. Стоимость Подписки указывается на Сайте.</li>
        <li>1.3. Оказание услуг Сопровождения осуществляется посредством функционала Программы, либо, если это требуется
          для конкретных задач Лицензиата, инструментами иного программного обеспечения по усмотрению Лицензиара.
        </li>
        <li>1.4. Иные условия оказания услуги Сопровождения и рабочий порядок коммуникации Сторон указываются на Сайте,
          а также могут быть согласованы Сторонами индивидуально.
        </li>
      </ul>

      <h4>2. Срок оказания услуг Сопровождения:</h4>
      <ul>
        <li>2.1. Срок оказания услуг Сопровождения составляет 1 отчетный период, оплаченный Лицензиатом. Оплаченный
          период указывается в Личном кабинете Программы.
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: "Offer",
};
</script>

<style scoped>
.container {
  max-width: 800px; /* Ограничьте ширину контейнера для лучшей читаемости */
}

h1, h2 {
  margin-bottom: 20px;
}

p, ul {
  margin-bottom: 15px;
}
</style>
